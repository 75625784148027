
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import DonateUs from './components/donateus.component';
import Team from './components/team.component';
import Edit from './components/edit.component';
import Index from './components/index.component';
import ContactUs from './components/contactus.component';
import OurActivities from './components/activities.component';
import Login from './components/Login/login.component';
import Logout from './components/Login/logout.component';
import Dashboard from './components/dashboard.component';
import AddReciept from './components/account/add_reciept.component';
import ViewDonations from './components/account/view_donations.component';
import ViewReciept from './components/account/view_reciept.component';
import AddMember from './components/account/add_member.component';
import AddHomeExpense from './components/home_app/add_expense.component';
import ListHomeExpense from './components/home_app/list_expense.component';
import { Navbar, Nav } from 'react-bootstrap';
import { createBrowserHistory } from "history";

const history = createBrowserHistory()
class App extends Component {

  render() {
    const login = localStorage.getItem("isLoggedIn");
    let afterLoginNavLink = (<div></div>);
    if (history.location.pathname != '/view-reciept') {
      afterLoginNavLink = (
        <div className="header">
          <div className="logo">
            <a href="index">Nirvair Daswand <span>Sewa Society</span></a>
          </div>
          <Navbar className="top-nav" bg="light" expand="lg">
            <Navbar.Brand href="#home">Menu</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                <Nav.Link href="/add-reciept">Add Reciept</Nav.Link>
                <Nav.Link href="/view-donations">View Donations</Nav.Link>
                <Nav.Link href="/add-member">Add Member</Nav.Link>
                <Nav.Link href="/add-home-expense">Add Member</Nav.Link>
                <Nav.Link href="/logout">Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="clearfix"> </div>
        </div>
      );
    }
    let beforeLoginNavLink = (
      <div className="header">
        <div className="logo">
          <a href="index">Nirvair Daswand <span>Sewa Society</span></a>
        </div>
        <Navbar className="top-nav" bg="light" expand="lg">
          <Navbar.Brand href="#home">Menu</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/index">Home</Nav.Link>
              <Nav.Link href="/our-activities">Our Activities</Nav.Link>
              <Nav.Link href="/team">Our Team</Nav.Link>
              <Nav.Link href="/donate-us">Donate Us</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="clearfix"> </div>
      </div>
    );
    return (
      <div className="App">
        {login ? (
          <Router>
            {afterLoginNavLink}
            <Switch>
              <Route path='/dashboard' component={Dashboard} />
              <Route path='/add-reciept' component={AddReciept} />
              <Route path='/view-donations' component={ViewDonations} />
              <Route path='/view-reciept' component={ViewReciept} />
              <Route path='/add-member' component={AddMember} />
              <Route path='/logout' component={Logout} />
              <Route path='/home/add-expense' component={AddHomeExpense} />
              <Route path='/home/list-expense' component={ListHomeExpense} />
            </Switch>
          </Router>
        ) : (
          <Router>
            {beforeLoginNavLink}
            <Switch>
              <Route exact path='/' component={Index} />
              <Route exact path='/Team' component={Team} />
              <Route exact path='/donate-us' component={DonateUs} />
              <Route path='/edit/:id' component={Edit} />
              <Route path='/index' component={Index} />
              <Route path='/contact-us' component={ContactUs} />
              <Route path='/our-activities' component={OurActivities} />
              <Route path='/login' component={Login} />
              <Route path='/view-reciept' component={ViewReciept} />
            </Switch>
          </Router>
        )}
      </div>
    );


  }
}

export default App;
