import { Component } from 'react';

export default class Logout extends Component {

  logout = () => {
    window.localStorage.clear();
    window.location.href = "login";
  }
  
  render() {
    ( this.logout() );
    return null;
  }
}