
import React, { Component } from 'react';
import axios from 'axios';


export default class AddMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: "",
          mobile: "",
          email: "",
          response_message: "",
          list_users: [],
        };
    };
    onChangehandler = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      let data = {};
      data[name] = value;
      this.setState(data);
    };

    onSubmitHandler = () => {
        this.setState({ isLoading: true });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('userToken')
        };
        axios.post(process.env.REACT_APP_API_URL+"/users/create", {
            name: this.state.name,
            mobile: this.state.mobile,
            email: this.state.email,
          },{
            headers: headers,
          })
          .then((response_final) => {
            let response = response_final.data;
            this.setState({
                response_message: response.message,
            });
            if(response.type === 'success'){
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
                this.setState({
                    response_message: response.message,
                });                
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
    render() {
        return (
            <div className="contact from-main" id="contact">
    <div className="container padding20">
        <div className="col-md-4 bg-white visible-lg">
    <div className="user-details">
        <div className="user-img">
        </div>
        <div className="user-name">
            <h3>UserName</h3>
        </div>
    </div>
    <div className="fundraising">
        <div className="pure-g">
            <div className="pure-4"><h4>Your fundraising</h4></div>
            <div className="pure-1"><a className="action-link" href="http://nirvairdaswandsewa.org/my-donations">See all</a></div>
        </div>
    </div>
</div>        <div className="col-md-12 contact-left from-bg">
            <h3>Add Member</h3>
                <form accept-charset="UTF-8" className="form-horizontal">
                <span className="text-danger">{this.state.response_message}</span>
            <div className="form-body">
                <div className="form-group row">
                    <label className="col-md-2">Name</label>
                    <div className="col-md-6">
                        <div className="input-icon right">
                            <input onChange={this.onChangehandler} className="form-control" name="name" type="text" />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-2 ">Mobile</label>
                    <div className="col-md-6">
                        <input className="form-control" onChange={this.onChangehandler} name="mobile" type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-2 ">Email</label>
                    <div className="col-md-6">
                        <input className="form-control" onChange={this.onChangehandler} name="email" type="text" />
                    </div>
                </div>
                <div className="form-actions">
                    <div className="row">
                        <div className="col-md-offset-3 col-md-9">
                            <button type="button" className="btn btn-primary " onClick={this.onSubmitHandler}>Submit</button>
                        </div>
                    </div>
                </div>

            </div>
            </form>
        </div>
        <div className="clearfix"> </div>
    </div>
</div>
        )
    }
}