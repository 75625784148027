
import React, { Component } from 'react';

export default class Index extends Component {
    render() {
        return (
            
<div class="contact" id="contact">
    <div class="container">
        <div class="col-md-5 contact-left">
            <h3>Address  </h3>
            <p>460, Street No. 5 ,Bachittar Nagar
                <span>Gill Road,Ludhiana (Punjab)</span></p>
            <ul>
                <li>Navpreet Singh :+1 91 81460-42638</li>
                <li>Shawinder Jit Singh:+1 91 89682-90482</li>
                <li><a href="mailto:info@nirvairdaswandsewa.org">info@nirvairdaswandsewa.org</a></li>
            </ul>
        </div>
        <div class="col-md-7 contact-left">
            <h3>Contact Form</h3>
            <div id="contact-msg-div">
            </div>
           <input type="text" value="name" name="name" required="" />
            <input type="text" value="email" name="email" />
            <input type="text" value="phone" name="phone" required="" />
            <textarea type="text" name="msg" required="required">Message...</textarea>
            <input type="submit" value="Submit" />
        </div>
        <div class="clearfix"> </div>
    </div>
</div>
        )
    }
}