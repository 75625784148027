import React, { Component } from 'react';
import axios from 'axios';


export default class AddReciepts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            amount: "",
            date: "",
            remarks: "",
            pay_mode: "",
            response_message: "",
            list_users: [],
        };
    };
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    componentDidMount() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };
        let url = process.env.REACT_APP_API_URL + `/users/list`;
        axios.post(url,{}, {
            headers: headers
        })
            .then(res => {
                const list_users = res.data;
                this.setState({ list_users });
            })
    }

    onSubmitHandler = () => {
        this.setState({ isLoading: true });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };
        axios.post(process.env.REACT_APP_API_URL + "/receipts/create", {
            user_id: this.state.user_id,
            amount: this.state.amount,
            date: this.state.date,
            mode: this.state.pay_mode,
            remarks: this.state.remarks,
        }, {
            headers: headers,
        })
            .then((response_final) => {
                let response = response_final.data;
                this.setState({
                    response_message: response.message,
                });
                if (response.type === 'success') {
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                    this.setState({
                        response_message: response.message,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    render() {
        return (
            <div className="contact from-main" id="contact">
                <div className="container padding20">
                    <div className="col-md-4 bg-white visible-lg">
                        <div className="user-details">
                            <div className="user-img">
                                {/* <img className="img-responsive" src={sideImage} alt=""/> */}
                            </div>
                            <div className="user-name">
                                <h3>UserName</h3>
                            </div>
                        </div>
                        <div className="fundraising">
                            <div className="pure-g">
                                <div className="pure-4"><h4>Your fundraising</h4></div>
                                <div className="pure-1"><a className="action-link" href="http://nirvairdaswandsewa.org/my-donations">See all</a></div>
                            </div>
                        </div>
                    </div>        <div className="col-md-12 contact-left from-bg">
                        <h3>Payment Form</h3>
                        <form acceptCharset="UTF-8" className="form-horizontal">

                            <span className="text-danger">{this.state.response_message}</span>
                            <div className="form-body">
                                <div className="form-group">
                                    <label className="col-md-3">User</label>
                                    <div className="col-md-4">
                                        <select onChange={this.onChangehandler} className="form-control" name="user_id">
                                            <option value="0">Choose User</option>
                                            {this.state.list_users.map(person =>
                                                <option value={person.id}>{person.first_name} ({person.mobile})</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-3">Amount</label>
                                    <div className="col-md-4">
                                        <div className="input-icon right">
                                            <input onChange={this.onChangehandler} className="form-control" name="amount" type="number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-3 ">Date</label>
                                    <div className="col-md-4">
                                        <input className="form-control" onChange={this.onChangehandler} name="date" type="date" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-3">Mode of Payment</label>
                                    <div className="col-md-4">
                                        <select className="form-control" onChange={this.onChangehandler} name="pay_mode"><option value="" defaultValue>Choose One</option><option value="1">Cash</option><option value="2">Online Transfer</option><option value="3">Cheque</option><option value="4">Draft</option><option value="5">Bank Transfer</option></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-3">Remarks</label>
                                    <div className="col-md-4">
                                        <textarea className="form-control" onChange={this.onChangehandler} cols="12" rows="5" name="remarks"></textarea>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <div className="row">
                                        <div className="col-md-offset-3 col-md-9">
                                            <button type="button" className="btn btn-primary " onClick={this.onSubmitHandler}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div className="clearfix"> </div>
                </div>
            </div>
        )
    }
}