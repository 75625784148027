
import React, { Component } from 'react';
import m1Image from '../images/navpreet-singh.png';
import m2Image from '../images/shawinder-jit-singh.jpg';
import m3Image from '../images/tejinder-singh.jpg';

export default class Index extends Component {
    render() {
        return (
            <div class="team" id="team">
                <div class="container">
                    <h3>Meet Our Team</h3>
                    <div class="line tem">
                    </div>
                    <p class="et">"The strength of the team is each individual member. The strength of each member is the team." --Phil Jackson.</p>
                    <div class="team-grids">
                        <div class="col-md-3 team-grid">
                            <img src={m1Image} width="157px" alt=" " class="img-responsive"/>
                            <h4>Navpreet Singh <br/><span>President</span></h4>
                            <div class="team-grid-hov">
                            </div>
                        </div>
                        <div class="col-md-3 team-grid">
                            <img src={m2Image} width="172px" alt=" " class="img-responsive" />
                            <h4>Shawinder Jit Singh <br/><span>Vice President</span></h4>
                            <div class="team-grid-hov">
                            </div>
                        </div>
                        <div class="col-md-3 team-grid">
                            <img src={m3Image} alt=" " width="172px" class="img-responsive" />
                            <h4>Tejinder Singh <br/><span>Verification Team Member</span></h4>
                            <div class="team-grid-hov">
                            </div>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
        )
    }
}