
import React, { Component } from 'react';

import axios from 'axios';

export default class Index extends Component {
    state = {
        list_data: []
    }
    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL+`/videos/list`)
            .then(res => {
                const list_data = res.data;
                this.setState({ list_data });
            })
    }

    render() {
        return (
            <div class="welcome">
                <div class="container">
                    <h3>Our Activities !</h3>
                    <div class="welcome-grids">
                        {this.state.list_data.map(person =>
                            <div class="col-md-12 welcome-grid">
                                <div class="welcome-grid-right aliquam">
                                    <a href={"https://youtube.com/watch?v="+person.id.videoId} target="_blank">
                                        <img src={person.youtube_img} alt=" " className="img-responsive" />
                                    </a>
                                </div>
                                <div class="welcome-grid-left">
                                    <h4>{person.title}</h4>
                                    <p>{person.desc}</p>
                                </div>
                                <div class="clearfix"> </div>
                            </div>)}
                    </div>
                </div>
            </div>
        )
    }
}