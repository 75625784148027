
import React, { Component } from 'react';

export default class Dashboard extends Component {
    render() {
        return (
            
<div className="contact" id="contacts">
    <div className="container">
        <div className="col-md-7 contact-left">
            <h3>Welcome to Dashboard</h3>
        </div>
        <div className="clearfix"> </div>
    </div>
</div>
        )
    }
}