import React, { Component } from 'react';
import axios from 'axios';
import { If, Then, ElseIf } from 'react-if-elseif-else-render';
import dateFormat from 'dateformat';

export default class ViewDonations extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user_id: "",
          amount: "",
          date: "",
          remarks: "",
          pay_mode: "",
          response_message: "",
          list_users: [],
        };
    };
    onChangehandler = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      let data = {};
      data[name] = value;
      this.setState(data);
    };
    componentDidMount() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('userToken')
        };
          let url = process.env.REACT_APP_API_URL+`/receipts/list`;
        axios.get(url,{
            headers: headers
          })
        .then(res => {
            const list_users = res.data;
            this.setState({ list_users });
        })
    }

    render() {        
        return (
            <div className="contact from-main" id="contact">
                <div className="container padding20">
                <div className="col-md-4 bg-white visible-lg">
                <div className="user-details">
                    <div className="user-img">
                    </div>
                    <div className="user-name">
                        <h3>UserName</h3>
                    </div>
                </div>
                <div className="fundraising">
                    <div className="pure-g">
                        <div className="pure-4"><h4>Your fundraising</h4></div>
                        <div className="pure-1"><a className="action-link" href="http://nirvairdaswandsewa.org/view-donations">See all</a></div>
                    </div>
                </div>
            </div>        
            <div className="col-md-12 contact-left from-bg">
            <h3>Donations</h3>
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Mode of Payment</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                                {this.state.list_users.map(person => 
                            <tr>
                                    <td>{person.users.first_name}</td>
                                    <td>{dateFormat(person.date, "d-mm-yyyy")} </td>
                                    <td>{person.amount} </td>
                                    <td>
                                    <If condition={person.mode === 1}>
                                        <Then>
                                            Cash
                                        </Then>
                                        <ElseIf condition={person.mode === 2}>
                                            Online Transfer
                                        </ElseIf>
                                        <ElseIf condition={person.mode === 3}>
                                            Cheque
                                        </ElseIf>
                                        <ElseIf condition={person.mode === 4}>
                                            Bank Transfer
                                        </ElseIf>
                                    </If>

                                     </td>
                                    <td>
                                        <a target="_blank" href={'view-reciept?id='+person.id} className="nav-link">View Reciept</a>
                                    </td>
                            </tr>
                                )}
                        </tbody>
                    </table>
                </div>
        </div>
        <div className="clearfix"> </div>
    </div>
</div>
        )
    }
}