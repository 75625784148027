import React, { Component } from 'react';

import axios from "axios";

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      msg: "",
      isLoading: false,
      redirect: false,
      error_message: ""
    };
  }
  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  onSignInHandler = () => {
    this.setState({ isLoading: true });
    axios
      .post(process.env.REACT_APP_API_URL+"/users/authenticate", {
        username: this.state.username,
        password: this.state.password,
      })
      .then((response_final) => {
        let response = response_final.data;
        this.setState({
            error_message: response.message,
        });
        this.setState({ isLoading: false });
        if (response.id > 0) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userToken", response.token);
            this.setState({
                msg: 'Login successfull',
                redirect: true,
            });      
        }
        if ( response.type === "error") {
          this.setState({
            error_message: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (this.state.redirect) {
        return window.location.href = "add-reciept";
    //   return <Redirect to="/dashboard" />;
    }
    const login = localStorage.getItem("isLoggedIn");
    if (login) {
      return window.location.href = "add-reciept";
    //   return <Redirect to="/home" />;
    }
    const isLoading = this.state.isLoading;
    return (
            <div className="team" id="team">
                <div className="container col-sm-3">
                    <h3>Please Login</h3>
                    <span className="text-danger">{this.state.error_message}</span>
                    <form>
                        Username <input type="text" name="username" onChange={this.onChangehandler} className="form-control" />
                        Password <input type="password" name="password" onChange={this.onChangehandler} className="form-control" />
                         <input type="button" className="btn btn-primary" value="Login" name="Login" onClick={this.onSignInHandler}
/>
                     </form>
                     {isLoading ? (
                        <span>Loading...</span>
                        ) : (
                        <span></span>
                        )}
                </div>
            </div>
        )
    }
}
