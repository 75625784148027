
import React, { Component } from 'react';
import donateImage from '../images/donate.jpg';

export default class Index extends Component {
    render() {
        return (
            <div className="services" id="donate-us">
            <div className="container">
                <h3>Donate Us</h3>
                <div className="line tem">
                </div>
                <div className="col-md-12 donate-img">
                    {/* style="padding-left: 12%;" */}
                    <img src={donateImage} alt=" Donate Us" className="img-responsive" />
                </div>
                <div className="clearfix"> </div>
            </div>
        </div>
        )
    }
}