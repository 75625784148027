import React, { Component } from 'react';
import axios from 'axios';
import { If, Then, ElseIf } from 'react-if-elseif-else-render';
import dateFormat from 'dateformat';
import '../../css/receipt.css';

export default class ReceiptDonations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rec_data: [],
        };
    };
    
    componentDidMount() {
        const receipt_id = window.location.search.split("=")[1];// fetch id from url
        
        let data = {'params':{'id':receipt_id}};
        let url = process.env.REACT_APP_API_URL+`/receipts/view`;
        axios.get(url,data)
            .then(res => {
                const rec_data = res.data;
                this.setState({ rec_data });
            })
    }

    render() {
        return (
    <div id="html-content-holder" class="container">
        <div class="header">
            <div class="first">ੴ  ਸਤਿਗੁਰ ਪ੍ਰਸਾਦਿ</div>
            <div class="main"><h2>ਨਿਰਵੈਰ ਦਸਵੰਧ ਸੇਵਾ ਸੁਸਾਇਟੀ (ਰਜਿ:)</h2></div>
            <div class="addr">460, ਗਲੀ ਨੰ 5,ਬਚਿੱਤਰ ਨਗਰ ,ਗਿੱਲ ਰੋਡ ,ਲੁਧਿਆਣਾ-141006 , ਮੋ. 81460-42638,89682-90482</div>
        </div>
        {this.state.rec_data.map(obj => 
        <div class="body">
            <div class="header-2">
                <div class="fl">ਨੰਬਰ : {obj.no}</div>
                <div class="fr">ਮਿਤੀ : {dateFormat(obj.date, "d-mm-yyyy")}</div>
            </div>    
            <div class="name">ਸ੍ਰੀਮਾਨ/ਸ੍ਰੀਮਤੀ  ____{obj.users.first_name}__________________________ ਜੀ ਪਾਸੋਂ</div>
            <div class="amt">ਰਕਮ ਅੱਖਰੀ _____{obj.amount}________________________</div>
            <div class="amt"> __ 
            <If condition={obj.mode === 1}>
                <Then>
                    Cash
                </Then>
                <ElseIf condition={obj.mode === 2}>
                    Online Transfer
                </ElseIf>
                <ElseIf condition={obj.mode === 3}>
                    Cheque
                </ElseIf>
                <ElseIf condition={obj.mode === 4}>
                    Bank Transfer
                </ElseIf>
            </If>
                 ________________ਰਾਹੀਂ ਧੰਨਵਾਦ ਸਹਿਤ ਵਸੂਲ ਪਾਏ</div>
            <div class="footer-1">
                <div class="footer-2">
                    <span>ਰੁਪਏ : {obj.amount}/-</span>
                </div>
            </div>
        </div>
        )}
    </div>)
    }
}