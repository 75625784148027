
import React, { Component } from 'react';

import donateImage from '../images/donate.jpg';
import bannerImage from '../images/banner.jpg';
import m1Image from '../images/navpreet-singh.png';
import m2Image from '../images/shawinder-jit-singh.jpg';
import m3Image from '../images/tejinder-singh.jpg';

export default class Index extends Component {
    render() {
        return (
            <div>
                <div className="banner" style={{backgroundImage:`url(${bannerImage})`}}>
                    <div className="container">
                        <section className="slider">
                            <div className="flexslider">
                                <ul className="slides">
                                    <li>
                                        <div className="banner-info">
                                            <h1>Dasvand</h1>
                                            <p>literally means "a tenth part" and refers the act of donating ten percent of one's harvest, both financial and in the form of time and service such as seva to needy people. It falls into Guru Nanak Dev's concept of Vand Chhako.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="team" id="team">
                    <div class="container">
                        <h3>Meet Our Team</h3>
                        <div class="line tem">
                        </div>
                        <p class="et">"The strength of the team is each individual member. The strength of each member is the team." --Phil Jackson.</p>
                        <div class="team-grids">
                            <div class="col-md-3 team-grid">
                                <img src={m1Image} width="157px" alt=" " class="img-responsive" />
                                <h4>Navpreet Singh <br /><span>President</span></h4>
                                <div class="team-grid-hov">
                                </div>
                            </div>
                            <div class="col-md-3 team-grid">
                                <img src={m2Image} width="172px" alt=" " class="img-responsive" />
                                <h4>Shawinder Jit Singh <br /><span>Vice President</span></h4>
                                <div class="team-grid-hov">
                                </div>
                            </div>
                            <div class="col-md-3 team-grid">
                                <img src={m3Image} alt=" " width="172px" class="img-responsive" />
                                <h4>Tejinder Singh <br /><span>Verification Team Member</span></h4>
                                <div class="team-grid-hov">
                                </div>
                            </div>
                            <div class="clearfix"> </div>
                        </div>
                    </div>
                </div>
                <div className="services" id="donate-us">
                    <div className="container">
                        <h3>Donate Us</h3>
                        <div className="line tem">
                        </div>
                        <div className="col-md-12 donate-img">
                            {/* style="padding-left: 12%;" */}
                            <img src={donateImage} alt=" Donate Us " className="img-responsive" />
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                </div>

                <div class="contact" id="contact">
                    <div class="container">
                        <div class="col-md-5 contact-left">
                            <h3>Address</h3>
                            <p>460, Street No. 5 ,Bachittar Nagar
                <span>Gill Road,Ludhiana (Punjab)</span></p>
                            <ul>
                                <li>Navpreet Singh :+1 91 81460-42638</li>
                                <li>Shawinder Jit Singh:+1 91 89682-90482</li>
                                <li><a href="mailto:info@nirvairdaswandsewa.org">info@nirvairdaswandsewa.org</a></li>
                            </ul>
                        </div>
                        <div class="col-md-7 contact-left">
                            <h3>Contact Form</h3>
                            <div id="contact-msg-div">
                            </div>
                            <input type="text" value="name" name="name" required="" />
                            <input type="text" value="email" name="email" />
                            <input type="text" value="phone" name="phone" required="" />
                            <textarea type="text" name="msg" required="required">Message...</textarea>
                            <input type="submit" value="Submit" />
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
        )
    }
}