import React, { Component } from 'react';
import axios from 'axios';
import { If, Then, ElseIf } from 'react-if-elseif-else-render';
import dateFormat from 'dateformat';

export default class ListHomeExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: "",
            to: "",
            total: 0,
            list: [],
        };
    };
    setList = (list) => {
        let sum = 0;
        list.forEach((element) => {
            sum += parseInt(element.amount);
        });
        this.setState({ list: list, total: sum });

    }
    onDelete = (id) => {
        if (window.confirm("Are you sure to delete this record?")) {

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('userToken')
            };

            let url = process.env.REACT_APP_API_URL + `/hm/expense-del?id=` + id;
            axios.get(url, {
                headers: headers
            })
                .then(res => {
                    this.get_list();
                })
        }
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    onSearch = () => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };

        let url = process.env.REACT_APP_API_URL + `/hm/expenses?from=` + (this.state.from) + `&to=` + (this.state.to);
        axios.get(url, {
            headers: headers
        })
            .then(res => {
                const list = res.data.data;
                let sum = 0;
                list.forEach((element) => {
                    sum += parseInt(element.amount);
                });
                this.setState({ list: list, total: sum });
            })
    };
    get_list() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };
        let url = process.env.REACT_APP_API_URL + `/hm/expenses`;
        axios.get(url, {
            headers: headers
        })
            .then(res => {
                const list = res.data.data;
                let sum = 0;
                list.forEach((element) => {
                    sum = parseInt(sum) + parseInt(element.amount);
                });
                this.setState({ list: list, total: sum });
            })
    }
    componentDidMount() {
        this.get_list();
    }

    render() {
        return (
            <div className="contact from-main" id="contact">
                <div className="container padding20">
                    <div className="col-md-4 bg-white visible-lg">
                        <div className="user-details">
                            <div className="user-img">
                            </div>
                            <div className="user-name">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                        <div className="fundraising">
                            <div className="pure-g">
                                <div className="pure-1"><a className="action-link" href="/home/add-expense">Add Data</a></div>
                                <div className="pure-1">Total : {this.state.total}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 contact-left from-bg">
                        <h3>My Records</h3>
                        <div className="col-md-12">
                            <div className='row col-md-12'>
                                <label className="col-md-2">Search</label>
                                <div className="col-md-4">
                                    From
                                    <input onChange={this.onChangehandler} className="form-control" name="from" type="date" placeholder='From' />
                                </div>
                                <div className="col-md-4">
                                    <div className="input-icon right">
                                        To
                                        <input onChange={this.onChangehandler} className="form-control" name="to" type="date" placeholder='To' />
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary " onClick={this.onSearch}>Search</button>

                            </div>
                            <table className="table">
                                <thead>
                                    <tr>                                            
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Category</th>
                                        <th>Remarks</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.list.map(p =>
                                        <tr>
                                            <td>{p.item}</td>
                                            <td>{dateFormat(p.date, "d-mm-yyyy")} </td>
                                            <td>{p.amount} </td>
                                            <td>{p.category_name} </td>
                                            <td>{p.remarks} </td>
                                            <td><a onClick={() => this.onDelete(p.id)}>Delete</a></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                </div>
            </div>
        )
    }
}