
import React, { Component } from 'react';

export default class Edit extends Component {
    render() {
        return (
            <div>
                <p>FATEH ! Welcome to Edit Component!!</p>
            </div>
        )
    }
}