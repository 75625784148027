import React, { Component } from 'react';
import axios from 'axios';


export default class AddExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            amount: "",
            date: "",
            remarks: "",
            item: "",
            response_message: "",
            categories: [],
            today_expenses: [],
            overview: {},
        };
    };
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };
    componentDidMount() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };
        let url = process.env.REACT_APP_API_URL + `/hm/categories`;
        axios.get(url, {}, {
        }).then(res => {
            this.setState({ 'categories': res.data });
        })
        url = process.env.REACT_APP_API_URL + `/hm/overview-data`;

        axios.get(url, {
            headers: headers
        }).then(res => {
            this.setState({ 'overview': res.data });
        })
        const currentDate = new Date().toJSON().slice(0, 10);
        url = process.env.REACT_APP_API_URL + `/hm/expenses?from=` + (currentDate) + `&to=` + (currentDate);
        axios.get(url, {
            headers: headers
        }).then(res => {
            this.setState({ 'today_expenses': res.data.data });
        })
    }

    onSubmitHandler = () => {
        if (this.state.category == '') {
            alert('Category Missing');
            return false;
        }
        this.setState({ isLoading: true });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        };
        axios.post(process.env.REACT_APP_API_URL + "/hm/expense", {
            item: this.state.item,
            amount: this.state.amount,
            date: this.state.date,
            category: this.state.category,
            tag: '',
            remarks: this.state.remarks,
        }, {
            headers: headers,
        })
            .then((response_final) => {
                let response = response_final.data;
                this.setState({
                    response_message: response.message,
                });
                if (response.type === 'success') {
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                    let setState = {
                        response_message: response.message,
                        overview: overview,
                    };
                    let overview = {
                        month_data: parseInt(this.state.overview.month_data) + parseInt(this.state.amount),
                    };

                    const currentDate = new Date().toJSON().slice(0, 10);
                    if (response.data.date == currentDate) {
                        overview.today_data = parseInt(this.state.overview.today_data) + parseInt(this.state.amount);
                        // setState.today_expenses = response.data;
                    }
                    this.setState(setState);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    render() {
        return (
            <div className="contact from-main" id="contact">
                <div className="container padding20">
                    <div className="col-md-4 bg-white visible-lg">
                        <div className="user-details">
                            <div className="user-img">
                                {/* <img className="img-responsive" src={sideImage} alt=""/> */}
                            </div>
                            <div className="user-name">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                        <div className="fundraising">
                            <div className="pure-g">
                                <div className="pure-1"><a className="action-link" href="/home/list-expense">View all</a></div>
                                <div className="pure-4">
                                    <h4>Curr Month Total : {this.state.overview.month_data}</h4>
                                    <h4>Today Total : {this.state.overview.today_data}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12  contact-left from-bg">
                        <div className='row'>
                            <div className='col-md-6'>
                                <h3>Add Home Expense Form</h3>
                                <form acceptCharset="UTF-8" className="form-horizontal">
                                    <span className="text-danger">{this.state.response_message}</span>
                                    <div className="form-body">
                                        <div className="form-group">
                                            <label className="col-md-3">Category</label>
                                            <div className="col-md-4">
                                                <select onChange={this.onChangehandler} className="form-control" name="category">
                                                    <option value="0">Choose Category</option>
                                                    {this.state.categories.map(cat =>
                                                        <option value={cat.id}>{cat.category_name} </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3">Amount</label>
                                            <div className="col-md-4">
                                                <div className="input-icon right">
                                                    <input onChange={this.onChangehandler} className="form-control" name="amount" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3">Item</label>
                                            <div className="col-md-4">
                                                <div className="input-icon right">
                                                    <input onChange={this.onChangehandler} className="form-control" name="item" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 ">Date</label>
                                            <div className="col-md-4">
                                                <input className="form-control" onChange={this.onChangehandler} name="date" type="date" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3">Remarks</label>
                                            <div className="col-md-4">
                                                <input className="form-control" onChange={this.onChangehandler} cols="12" rows="5" name="remarks" />
                                            </div>
                                        </div>
                                        <div className="form-actions">
                                            <div className="row">
                                                <div className="col-md-offset-3 col-md-9">
                                                    <button type="button" className="btn btn-primary " onClick={this.onSubmitHandler}>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                Today Expenses

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Amount</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.today_expenses.map(p =>
                                            <tr>
                                                <td>{p.item}</td>
                                                <td>{p.amount} </td>
                                                <td>{p.category_name} </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                </div>
            </div>
        )
    }
}